<template>
    <form class="mt-5 text-white">
        <div class="form-group">
            <label class="my-3" for="name"><strong>Name</strong></label>
            <input type="text" v-model="name" class="form-control shadow" id="name" placeholder="Enter Name">
        </div>
        <div class="form-group">
            <label class="my-3" for="email"><strong>Email address</strong></label>
            <input type="email" v-model="email" class="form-control shadow" id="email" aria-describedby="emailHelp"
                placeholder="Enter email">
        </div>
        <div class="form-group">
            <label class="my-3" for="exampleFormControlTextarea1"><strong>Message</strong></label>
            <textarea class="form-control shadow" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
        <button type="submit" @click.prevent="showModal" class="btn btn-primary mt-5" data-toggle="modal"
            data-target="#myModal">Submit</button>
    </form>
    <ErrorModal />
</template>

<script>
import ErrorModal from './ErrorModal.vue';

export default {
    name: 'ContactForm',
    data() {
        return {
            name: "",
            email: "",
            message: "",
            show: false,
        }
    },
    components: {
        ErrorModal,
    },
    methods: {
        showModal() {
            document.getElementById("myModal").modal("show");
        }
    },
    computed: {

    }
}

</script>

<style scoped></style>
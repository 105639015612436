<template>
  <div>
    <CenterPiece />
    <div class="row">
      <div class="col-sm-12 col-md-6 pt-3">
        <TechArea />

      </div>
      <div class="col-sm-12 col-md-6 pt-3">
        <SmallAbout />
      </div>
    </div>
    <div class="spacer">

    </div>
  </div>
</template>

<script>
import CenterPiece from '..//components/CenterPiece.vue';
import TechArea from '..//components/TechArea.vue';
import SmallAbout from '..//components/SmallAbout.vue';

export default {
  components: {
    CenterPiece,
    TechArea,
    SmallAbout,
  },
  data() {
    return {
      windowWidth: 0,
    }
  },
  mounted() {
    this.windowWidth = document.documentElement.clientWidth;
  },
  return: {

  }
}
</script>

<style scoped>
.spacer {
  height: 150px;
}
</style>
<template>
    <div class="row">
        <div class="col-sm-12">
            <h1 class="jumbotron text-white text-center pb-5">Resume</h1>
            <iframe :src="`${publicPath}resume.pdf`" frameborder="0" width="100%" height="900"></iframe>
        </div>
    </div>
</template>


<script>


export default{
    name: 'ResumeRoute',
    data(){
        return{
            publicPath: process.env.BASE_URL,
        }
    },
    components:{

    },
    methods:{

    },
    computed:{

    }
}
</script>

<style scoped>

</style>

<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="w-50 m-auto">
                <h1 class="jumbotron text-center text-white">Contact</h1>
                <div class="text-white">
                    <span>Phone: </span>
                    <a href="tel:4174134134">
                        <p><strong>417-413-4134</strong></p>
                    </a>
                    <span>Email: </span>
                    <a href="mailto:jeff@jeffmitchell.xyz">
                        <p><strong>jeff@jeffmitchell.xyz</strong></p>
                    </a>
                </div>
                <div class="col-sm-12">
                    <ContactForm />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ContactForm from './ContactForm.vue';

export default {
    name: 'ContactRoute',
    data() {
        return {

        }
    },
    components: {
        ContactForm
    },
    methods: {

    }
}
</script>


<style scoped></style>
<template>
    <div class="row mb-5">
        <div class="col-sm-12">
            <div class="text-center abtBack">
           <h2 class="">About Me</h2>
           <p>My name is Jeff Mitchell, I am currently a software developer for O'Reilly Auto Parts and MSU graduate with a degree in Computer Science, I love building beautiful websites and using my skills to solve the needs of your business.</p>
            </div>  
        </div>
    </div>
</template>

<script>
export default {
    name: 'SmallAbout',
    data() {
        return {
            message: 'Hello Vue!',
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <img class="img-fluid" @mouseover="toggleTooltip" @mouseleave="toggleTooltip" @mousemove="updateComponentPosition"
        :src="require(`../assets/${svgSource}`)" alt="techIcon" />
    <DetailToolTip class="shadow" v-if="show && !isMobileViewport" :title="title" :description="description"
        :componentX="tooltipX" :componentY="tooltipY" />
</template>

<script>
import DetailToolTip from './DetailToolTip.vue'

export default {
    name: 'TechIcon',
    data() {
        return {
            show: false,
            componentX: 0,
            componentY: 0,
            mousePosition: {
                x: 0,
                y: 0
            },
            windowWidth: 0,
        }
    },
    mounted() {
        this.windowWidth = window.innerWidth;
    },
    components: {
        DetailToolTip
    },
    computed: {
        tooltipX() {
            const offset = 10; // Adjust this offset if needed
            return this.mousePosition.x + offset;
        },
        tooltipY() {
            const offset = 10; // Adjust this offset if needed
            return this.mousePosition.y + offset;
        },
        isMobileViewport() {
            return this.windowWidth < 768; // Adjust the breakpoint as needed
        },
    },
    props: {
        svgSource: {
            type: String,
            default: "icons8-html.svg",
            required: true
        },
        description: {
            type: String,
            required: true,
            default: "icon description"
        },
        title: {
            type: String,
            required: true,
            default: "icon title"
        }
    },
    methods: {
        toggleTooltip() {
            this.show = !this.show;
        },
        updateComponentPosition(event) {
            this.mousePosition.x = event.pageX;
            this.mousePosition.y = event.pageY;
        }

    },
}
</script>

<style scoped>
img {
    transition: box-shadow 0.3s;
}

img:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-3px);
}
</style>

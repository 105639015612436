<template>
  <StyleBar />
  <div class="container">
    <router-view v-slot="{ Component }">
      <transition name='fade'>
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import StyleBar from './components/StlyeBar.vue';


export default {
  name: 'App',
  components: {
    StyleBar,
  }
}
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
  /*background: linear-gradient(180deg, rgba(2, 0, 36, 1) 3%, rgba(137, 143, 145, 1) 100%, rgba(16, 16, 17, 0.5467436974789917) 100%);*/
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: 'Inter', sans-serif;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;

}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

body::-webkit-scrollbar {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="text-center justify-content-center d-table">
                <h2>My Tech</h2>
                <ul class="list-group list-group-horizontal list-unstyled text-center">
                    <li v-for="n in 11" :key="n">
                        <TechIcon :svgSource="svgs.icons[n - 1]" :title="svgs.title[n - 1]"
                            :description="svgs.description[n - 1]" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import TechIcon from './TechIcon.vue'

export default {
    name: 'TechArea',
    data() {
        return {
            svgs: {
                icons: ["icons8-html.svg", "icons8-css.svg", "icons8-javascript.svg", "icons8-vue-js.svg", "icons8-mysql.svg", "icons8-spring-boot.svg", "icons8-nodejs.svg", "icons8-c.svg", "icons8-c-sharp-logo.svg", "icons8-cc.svg", "icons8-java.svg"],
                title: ["HTML", "CSS", "JavaScript", "Vue.js", "MySQL", "Spring Boot", "Node.js", "C++", "C#", "C", "Java"],
                description: ["The building blocks of any website, i have 2 years of experience",
                    "We dont want a boring web page do we, well versed in vanilla CSS and Bootstrap, working on learning tailwind, also 2 years of experience",
                    "Ive done most of my Javascript coding with vanila JavaScript but recently have taken up vue and it is fantastic, 2 years experience",
                    "Just picked up Vue, only a few months of experience, more to come, this website is written in Vue",
                    "I have experience writing raw SQL queries and using ORMs like Hibernate, 1 years experience",
                    "This is the back end we use at O'Reilly, 1 years experience",
                    "Most of my personal projects are done using node, 1 years experience, typically with express",
                    "Although its not particularly useful in web dev, i love C++ and have 5 years of experience, i have written a few games in C++",
                    "Microsoft Java, 2 years experience, written a few desktop apps and games with unity",
                    "This is the one that started it all, this was my first language, 5 years experience, The old testament in my opinion",
                    "C++ but you have to use Object Oriented Programming, 5 years experience"]
            }
        }
    },
    components: {
        TechIcon
    },
    methods: {

    },
}
</script>

import { createApp } from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import router from './router';
//import '@fortawesome/fontawesome-free/css/all.css';
import App from './App.vue'
library.add(faUserSecret,faTwitter);


createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(router).mount('#app')

<template>
    <div class="toolTipContainer border border-dark" :style="{ left: dynamicX + 'px', top: dynamicY + 'px' }">
        <div class="row">
            <div class="col-sm-12">
                <h3> {{ title }}</h3>
                <p>{{ description }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DetailToolTip',
    data() {
        return {

        }
    },
    methods: {

    },
    watch: {
        // componentX() {
        //     this.dynamicX = this.componentX;
        // },
        // componentY() {
        //     this.dynamicY = this.componentY;
        // }
    },
    computed: {
        dynamicX() {
            return this.componentX;
        },
        dynamicY() {
            return this.componentY;
        }
    },
    components: {
    },
    props: {
        title: {
            type: String,
            required: true,
            default: "icon title"
        },
        description: {
            type: String,
            required: true,
            default: "icon description"
        },
        componentX: {
            type: Number,
            required: true,
            default: 0
        },
        componentY: {
            type: Number,
            required: true,
            default: 0
        }
    }
}
</script>

<style scoped>
.toolTipContainer {
    position: absolute;
    width: 15%;
    height: auto;
    z-index: 1;
    background-color: aliceblue;
    opacity: 0.4;
}
</style>
